import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import {inject} from "mobx-react";
import config from "../../config/main.config";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

const regexpMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexpPhone = /^\+?\d*$/;

const PAGE_LINK = "Kontakt";

@inject("stores")
class ContactPage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true,
                conditionsChecked: false,
                newsletterChecked: false,
                contactPersonError: false,
                contactPerson: "",
                phone: "",
                email: "",
                message: "",
                address: "",
                zipCode: "",
                city: "",
                phoneError: false,
                emailError: false,
                messageError: false,
                conditionsCheckedError: false,
                modalOpen: false,
                modalMessage: "",
            }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        window.scrollTo(0, 0);

    }

    handleSearchChange(type, event, data) {
        switch (type) {
            case "contactPerson":
                this.setState({...this.state, contactPerson: data.value});
                break;

            case "phone":
                if (data.value.match(regexpPhone) || data.value === '') {
                    this.setState({...this.state, phone: data.value});
                }
                break;
            case "email":
                this.setState({...this.state, email: data.value});
                break;
            case "message":
                this.setState({...this.state, message: data.value});
                break;
            case "city":
                this.setState({...this.state, city: data.value});
                break;
            case "zip":
                if (data.value.match(regexpPhone) || data.value === '') {
                    this.setState({...this.state, zipCode: data.value});
                }
                break;
            case "address":
                this.setState({...this.state, address: data.value});
                break;
            default:
                break;
        }
    }

    submitMessage() {
        let canSend = true;
        //first validate data

        if (this.state.contactPerson.length > 0) {
            this.setState({...this.state, contactPersonError: false});
        } else {
            this.setState({...this.state, contactPersonError: true});
            canSend = false;

        }

        if (this.state.email.match(regexpMail) && this.state.email.length > 0) {
            this.setState({...this.state, emailError: false});
        } else {
            this.setState({...this.state, emailError: true});
            canSend = false;

        }
        if (this.state.conditionsChecked) {
            this.setState({...this.state, conditionsCheckedError: false});
        } else {
            this.setState({...this.state, conditionsCheckedError: true});
            canSend = false;

        }
        if(this.state.contactPersonError || this.state.emailError || this.state.conditionsCheckedError){
            return;
        }
        /* if (this.phone.length > 0) {
     this.phoneError = false;
 } else {
     this.phoneError = true;
     canSend = false;
 }*/

        if (canSend) {
            //Send to server
            let data = {
                contactPerson: this.state.contactPerson,
                phone: this.state.phone,
                email: this.state.email,
                message: this.state.message,
                address: this.state.address,
                city: this.state.city,
                zipCode: this.state.zipCode,
                newsletter: this.state.newsletterChecked ? 1 : 0
            };

            let self = this;

            fetch(config.BASE_URL + 'contact', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("success") && json) {
                            self.setState({...self.state, modalMessage: "Ihre Nachricht wird bearbeitet"})
                        } else {
                            self.setState({
                                ...self.state,
                                modalMessage: "Es ist ein Fehler unterlaufen. Bitte kontaktieren Sie uns direkt"
                            })
                        }
                        self.setState({...self.state, modalOpen: true})
                    });
                } else {
                    self.setState({
                        ...self.state,
                        modalMessage: "Es ist ein Fehler unterlaufen. Bitte kontaktieren Sie uns direkt"
                    })
                    self.setState({...self.state, modalOpen: true})
                }
            }).catch(() => {
                self.setState({
                    ...self.state,
                    modalMessage: "Es ist ein Fehler unterlaufen. Bitte kontaktieren Sie uns direkt"
                })
                self.setState({...self.state, modalOpen: true})
            });
        }
    }

    render() {
        return (
            <Grid>
                <TitleImageComponent
                    namespace={'contact'}
                    textStore={this.state.textStore}
                    imageLinkId={"contact-title-image-id"}
                    headerId={"contact-title-header-id"}
                    subheaderId={"contact-subtitle-header-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>KONTAKT</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Modal
                    style={{"margin": "auto"}}
                    open={this.state.modalOpen}
                    onClose={() => this.setState({...this.state, modalOpen: false})}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.state.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => browserHistory.push("/")}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid.Row centered>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <Form>
                            <Form.Input width={8}
                                        label="Name*"
                                        value={this.state.contactPerson}
                                        error={this.state.contactPersonError}
                                        onChange={this.handleSearchChange.bind(this, "contactPerson")}
                            />


                            <Form.Group widths={"equal"}>
                                <Form.Input
                                    label="E-Mail*"
                                    value={this.state.email}
                                    error={this.state.emailError}
                                    onChange={this.handleSearchChange.bind(this, "email")}
                                />
                                <Form.Input
                                    label="Telefon"
                                    value={this.state.phone}
                                    error={this.state.phoneError}
                                    onChange={this.handleSearchChange.bind(this, "phone")}
                                />
                            </Form.Group>
                            <Form.Input
                                width={8}
                                label="Straße und Hausnummer"
                                value={this.state.address}
                                onChange={this.handleSearchChange.bind(this, "address")}
                            />
                            <Form.Group widths={"equal"}>
                                <Form.Input
                                    label="Postleitzahl"
                                    value={this.state.zipCode}
                                    onChange={this.handleSearchChange.bind(this, "zip")}
                                />
                                <Form.Input
                                    label="Stadt"
                                    value={this.state.city}
                                    onChange={this.handleSearchChange.bind(this, "city")}
                                />
                            </Form.Group>
                            <Form.TextArea
                                label="Mitteilung"
                                value={this.state.message}
                                error={this.state.messageError}
                                onChange={this.handleSearchChange.bind(this, "message")}
                            />
                            <Form.Field>
                                <label>Anmeldung zum Stifterbrief/Newsletter</label>
                                <Checkbox
                                    style={{"marginTop": "10px", "marginRight": "5px"}}
                                    onChange={() => {
                                        if (this.state.newsletterChecked) {
                                            this.setState({...this.state, newsletterChecked: false});
                                        } else {
                                            this.setState({...this.state, newsletterChecked: true});
                                        }
                                    }}
                                    checked={this.state.newsletterChecked}
                                />
                                Ich möchte den jährlichen Stifterbrief per Post sowie weitere Informationen (z. B.
                                Newsletter) per E-Mail rund um die Projekte und Stiftungstätigkeiten erhalten.

                            </Form.Field>
                            <Form.Field
                                error={this.state.conditionsCheckedError}>
                                <label>Datenschutzhinweise*</label>
                                <Checkbox
                                    style={{"marginTop": "10px", "marginRight": "5px"}}
                                    onChange={() => {
                                        if (this.state.conditionsChecked) {
                                            this.setState({...this.state, conditionsChecked: false});
                                        } else {
                                            this.setState({...this.state, conditionsChecked: true});
                                        }
                                    }}
                                    checked={this.state.conditionsChecked}
                                />
                                Mit Setzen des Häkchens erklären Sie sich einverstanden, dass die von Ihnen angegebenen
                                Daten zweckgebunden zur Beantwortung Ihrer Anfrage elektronisch erhoben und gespeichert
                                werden. Diese Einwilligung können Sie jederzeit durch eine Nachricht an uns widerrufen.
                                In diesem Fall werden Ihre Daten umgehend gelöscht. Weitere Informationen entnehmen Sie
                                bitte unserer &nbsp;
                                <a onClick={() => browserHistory.push("/dataProtection")}
                                   className={"clicky-thingy"}>
                                    Datenschutzerklärung
                                </a>
                                .
                            </Form.Field>
                            <Button type='submit'
                                    className={"glw-button"}
                                    onClick={() => this.submitMessage()}>
                                Absenden
                            </Button>
                            <p>* = Pflichtfeld</p>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default ContactPage
//  const Seperator = "http://3dd0fd0.online-server.cloud/images/seperator.png";
/*      <Grid.Row centered>
          <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
              <Image centered
                     src={Seperator}
                     size={"tiny"}
              />
              <h1>
                  <EditSimpleValueComponent id={"contact-title-sector-1-id"}/>
              </h1>
              <p className={"text-block"}>
                  <EditTextComponent id={"contact-icon-card-1-subheader-id"}/>
              </p>
          </Grid.Column>
      </Grid.Row>*/
